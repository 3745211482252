import React from "react"
import { MDBContainer } from "mdbreact"

import Layout from "../components/TLCLayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout heroTitle="Error 404: Not Found" heroImageUrl="../images/hero-dark.jpg">
    <SEO title="404: Not found" />
    <MDBContainer>
      <h1>NOT FOUND</h1>
      <p>The page you've requested does not exist... the sadness.</p>
    </MDBContainer>
  </Layout>
)

export default NotFoundPage
